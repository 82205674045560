@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Pacifico&display=swap");

#root,
body,
html {
  height: 100%;
}

.simplebar-scrollbar:before {
  background: #fff;
}

input[type="checkbox"]:disabled ~ svg {
  opacity: 0.1;
}

.no-select div {
  user-select: none;
}
