@import url(https://fonts.googleapis.com/css?family=Bree+Serif|Pacifico&display=swap);
.spinner {
  width: 32px;
  height: 32px;
  position: relative;

  -webkit-animation: rotate 2s infinite linear;

          animation: rotate 2s infinite linear;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.textarea-auto-size {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: block;
  width: 100%;
  -webkit-appearance: none;
          appearance: none;
  font-size: inherit;
  line-height: inherit;
  border-radius: 4px;
  color: inherit;
  background-color: transparent;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", sans-serif;
  padding: 4px;
  border: none;
  resize: none;
}

.textarea-auto-size:focus {
  outline: none;
}

#root,
body,
html {
  height: 100%;
}

.simplebar-scrollbar:before {
  background: #fff;
}

input[type="checkbox"]:disabled ~ svg {
  opacity: 0.1;
}

.no-select div {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

